import { lazy } from "react";
const PaymentCreditDebit = lazy(() =>
  import("./components/AR/PaymentCreditDebit")
);
const Icons = lazy(() => import("./components/Developer/Icons/index"));
const TokenGenerator = lazy(() => import("./components/Developer/TokenGenerator"));
const ContactEdit = lazy(() => import("./components/ContactEdit"));
const PrepayApply = lazy(() => import("./components/AR/PrepayApply"));
const JobSearch = lazy(() => import("./components/AR/JobSearch"));
const BatchBilling = lazy(() => import("./components/AR/BatchBilling"));
const JobView = lazy(() =>
  import("./components/AR/JobSearch/components/JobView")
);
const AddContact = lazy(() => import("./components/AddContact"));
const Blank = lazy(() => import("./components/Blank"));
const Search = lazy(() => import("./components/Search"));
const AnalyticsDashboard = lazy(() =>
  import("./components/Users/AnalyticsDashboard/index")
);
const DefaultInstallmentDates = lazy(() =>
  import("./components/Admin/DefaultInstallmentDates")
);
const CustomerPortalSettings = lazy(() =>
  import("./components/Admin/CustomerPortalSettings")
);
const CreateAPIUserToken = lazy(() =>
  import("./components/Admin/CreateAPIUserToken")
);
const CreateSSOUserToken = lazy(() =>
  import("./components/Admin/CreateSSOToken")
);
const HelpSite = lazy(() =>
  import("./components/Admin/CreateSSOToken/HelpSite")
);
const PublisherInfo = lazy(() => import("./components/Admin/PublisherInfo"));
const IndexBase = lazy(() => import("./components/Templates"));
const TemplateDetails = lazy(() =>
  import("./components/Templates/TemplateDetails")
);
const EditorialManagement = lazy(() =>
  import("./components/Editorial/Management")
);
const EditorialAdd = lazy(() => import("./components/Editorial/Add"));
const EditDistribution = lazy(() =>
  import("./components/Distribution/editDistribution")
);
const DistributionReturns = lazy(() =>
  import("./components/Distribution/DistributionReturns")
);
const ContactSearch = lazy(() => import("./components/ContactSearch"));
const ModuleCriteria = lazy(() => import("./components/common/ModuleCriteria"));
const TemplatePreview = lazy(() =>
  import("./components/Templates/TemplatePreview")
);
const OpportunityAdd = lazy(() => import("./components/Opportunities/Add"));
const Opportunities = lazy(() => import("./components/Opportunities"));
const JobJacket = lazy(() =>
  import("./components/Production/Orders/JobJacket")
);
const CustomerApprovalInternal = lazy(() =>
  import("./components/Activities/CustomerApprovalInternal")
);
const WebsiteSetup = lazy(() => import("./components/WebsiteSetup/index"));
const BillSearch = lazy(() => import("./components/PaymentPlan/Installment"));
const QuickBooks = lazy(() => import("./components/QuickBooks"));
const OrderEntry = lazy(() => import("./components/Production/Orders/index"));
const CreateOrderWithNoPrice = lazy(() =>
  import("./components/Production/Orders/CreateOrderWithNoPrice")
);
const CreateOrder = lazy(() =>
  import("./components/Production/Orders/CreateOrder")
);
const EditOrder = lazy(() =>
  import("./components/Production/Orders/EditOrder")
);
const OrderList = lazy(() => import("./components/Production/OrderList/index"));
const ProposalList = lazy(() =>
  import("./components/Production/Orders/ProposalList/ProposalList")
);
const ChangeOrderList = lazy(() =>
  import("./components/Production/Orders/ChangeOrderList/ChangeOrderList")
);
const ProposalEntry = lazy(() =>
  import("./components/Production/Proposals/index")
);
const MultipleProducts = lazy(() =>
  import("./components/Production/Proposals/MultipleProducts")
);
const ChangeOrderEntry = lazy(() =>
  import("./components/Production/ChangeOrder/index")
);

const ProposalLineItemTemplate = lazy(() =>
  import("./components/Production/Proposals/ProposalTempSelection")
);
const QBSetup = lazy(() => import("./components/QuickBooks/QBSetup"));
const LinkAccounts = lazy(() => import("./components/QuickBooks/LinkAccounts"));
const LinkCustomers = lazy(() =>
  import("./components/QuickBooks/LinkCustomers")
);
const Coupons = lazy(() => import("./components/Subscription/Coupons/index"));
const CreateCoupon = lazy(() =>
  import("./components/Subscription/Coupons/Create")
);
const EditCoupon = lazy(() => import("./components/Subscription/Coupons/Edit"));
const Plans = lazy(() => import("./components/Subscription/Plans"));
const CreatePlan = lazy(() => import("./components/Subscription/Plans/Create"));
const EditPlan = lazy(() => import("./components/Subscription/Plans/Edit"));
const Subscriptions = lazy(() =>
  import("./components/Subscription/Subscriptions/index")
);
const CreateSubscription = lazy(() =>
  import("./components/Subscription/Subscriptions/Create")
);
const EditSubscription = lazy(() =>
  import("./components/Subscription/Subscriptions/Edit")
);
const RenewSubscription = lazy(() =>
  import("./components/Subscription/Subscriptions/Renew")
);
const InBetweenSubscription = lazy(() =>
  import("./components/Subscription/Subscriptions/InBetween")
);
const CopySubscription = lazy(() =>
  import("./components/Subscription/Subscriptions/CopySubscription")
);
const SubscriptionView = lazy(() =>
  import("./components/Subscription/Subscriptions/SubscriptionView")
);
const Addon = lazy(() => import("./components/Subscription/Addon/index"));
const CreateAddon = lazy(() =>
  import("./components/Subscription/Addon/Create")
);
const EditAddon = lazy(() => import("./components/Subscription/Addon/Edit"));
const GmailTemplate = lazy(() =>
  import("./components/ContactSearch/GmailTemplate")
);
const GmailTemplateEdit = lazy(() =>
  import("./components/ContactSearch/GmailTemplateEdit")
);
const SubscriptionBasicSearch = lazy(() =>
  import("./components/Subscription/SubscriptionBasicSearch/index")
);
const FulfilmentPoints = lazy(() =>
  import("./components/Subscription/FullfillmentPoints/index")
);
const CancelReasons = lazy(() =>
  import("./components/Subscription/CancelReason/index")
);
const GmailDetailsView = lazy(() =>
  import("./components/Activities/EmailDetails")
);
const Taxes = lazy(() => import("./components/Admin/Taxes"));
const Invoices = lazy(() => import("./components/AR/InvoiceSearch/index"));
const InvoicesCreate = lazy(() =>
  import("./components/AR/InvoiceCreate/index")
);
const Automation = lazy(() =>
  import("./components/AR/Automation/index")
);
const ScheduleReport = lazy(() =>
  import("./components/common/ScheduleReport/index")
);
const Source = lazy(() => import("./components/Subscription/Source/index"));
const OrderSearch = lazy(() =>
  import("./components/Production/OrderSearch/index")
);
const ChangeOrderSearch = lazy(() =>
  import("./components/Production/ChangeOrderSearch/index")
);
const CampaignSearch = lazy(() => import("./components/CampaignSearch/index"));

const Dashboards = lazy(() => import("./components/Dashboards"));
const Dummy1 = lazy(() => import("./components/Blank/Dummy1"));
const LineItems = lazy(() => import("./components/Templates/LineItems/index"));
const CreateLineItem = lazy(() =>
  import("./components/Templates/LineItems/Create")
);

const LinesItemThemes = lazy(() =>
  import("./components/Templates/LineItems/Themes")
);

const CreateLineItemTheme = lazy(() =>
  import("./components/Templates/LineItems/Themes/Create")
);

const EditLineItem = lazy(() =>
  import("./components/Templates/LineItems/Edit")
);
const BasicOrderSearch = lazy(() =>
  import("./components/Production/OrderSearch/index")
);
// const BasicOrderSearch = lazy(() => import('./components/Production/OrderSearch/BasicSearch'));
const Notifications = lazy(() => import("./components/Notifications/index"));
const ProductionTypes = lazy(() =>
  import("./components/Production/ProductionTypes/index")
);
const TransactionReport = lazy(() =>
  import("./components/AR/Report/TransactionReport/index")
);
const AgingReport = lazy(() =>
  import("./components/AR/Report/AgingSummaryReport/index")
);
const AWSBillingReport = lazy(() =>
  import("./components/AR/Report/FileStorageBillingReport/index")
);
const OpenAI = lazy(() => import("./components/OpenAI/index"));
const AssistantAPI = lazy(() => import("./components/AssistantAPI/index"));
const AILetterWriter = lazy(() => import("./components/AILetterWriter/index"));
const AITextImprover = lazy(() => import("./components/AITextImprover/index"));
const SalesModule = lazy(() =>
  import("./components/SalesModule/OrderSearch/index")
);
const SalesByModule = lazy(() => import("./components/SalesByModule/index"));
const Inventory = lazy(() => import("./components/Reports/Inventory/Index"));
const ActivitiesSearch = lazy(() =>
  import("./components/Activities/ActivitiesSearch/index")
);
const AddonGroup = lazy(() =>
  import("./components/Subscription/AddonGroup/index")
);
const ProductionOrderStages = lazy(() =>
  import("./components/Production/ProjectStages")
);
const OpportunityStages = lazy(() =>
  import("./components/Opportunities/OpportunityStages")
);
const ProspectingStages = lazy(() =>
  import("./components/ContactSearch/ProspectingStages")
);
const RequiredFields = lazy(() => import("./components/Admin/RequiredFields"));

const LeadTypes = lazy(() => import("./components/ContactSearch/LeadTypes"));

const RenewProposal = lazy(() => import("./components/ProposalRenew/index"));
const MultiCurrency = lazy(() =>
  import("./components/Admin/MultiCurrency/index")
);
const Statements = lazy(() => import("./components/AR/Statements/index"));
const Ratecards = lazy(() => import("./components/Admin/RateCards/index"));
const PayWithSavedCards = lazy(() =>
  import("./components/AR/PayWithSavedCards")
);
const ProjectFields = lazy(() =>
  import("./components/Admin/ProjectCustomFields/index")
);
const CustomFields = lazy(() =>
  import("./components/Admin/CustomFields/index")
);
const ContactGroups = lazy(() =>
  import("./components/Admin/ContactGroups/index")
);
const ContactTypes = lazy(() =>
  import("./components/Admin/ContactTypes/index")
);
const ManageProfileService = lazy(() =>
  import("./components/ProfileService/index")
);
const APIUserAccount = lazy(() => import("./components/Users/index"));
const AddOrEditUsers = lazy(() =>
  import("./components/Users/AddOrEditUser/index")
);
const UserList = lazy(() => import("./components/Users/List/index"));
const UserAccount = lazy(() => import("./components/Users/Account/index"));
const UserRolesList = lazy(() => import("./components/Users/Roles/List/index"));
const UserRoleAccount = lazy(() =>
  import("./components/Users/Roles/Account/index")
);
const ImagePreview = lazy(() => import("./components/Activities/ImagePreview"));
const DigitalAdsMapping = lazy(() => import("./components/DigitalAds/index"));
const GAMCreatives = lazy(() => import("./components/GAM/index"));

const CELogin = lazy(() => import("./components/CE/Auth/CELogin"));
const DisplayNotes = lazy(() => import("./components/CE/DisplayNotes"));

// const DisplayNotes = lazy(() => import("./components/CE/Auth/GooglePkg"));
const AddEmailNote = lazy(() => import("./components/CE/AddEmailNote"));
const Authentication = lazy(() =>
  import("./components/CE/Auth/Authentication")
);
const GmailRedirect = lazy(() =>
  import("./components/CE/Auth/RedirectAuthDetails")
);
const CalendarEvents = lazy(() => import("./components/Calendar/index"));

const SummaryDashboard = lazy(() =>
  import("./components/common/ModuleSummary")
);
const Proposals = lazy(() =>
  import("./components/CustomerPortal/Customer/CustomerHome/Proposals")
);
const SignTermsAndConditions = lazy(() =>
  import(
    "./components/CustomerPortal/Customer/CustomerHome/SignTermsConditions"
  )
);
const EfficiencyAnalyser = lazy(() =>
  import("./components/EfficiencyAnalyser/index")
);
const ShareReport = lazy(() => import("./components/common/ShareReport"));
const EmailFooter = lazy(() => import("./components/WebsiteSetup/EmailFooter"));
const ActivityModule = lazy(() => import("./components/ActivityModule/index"));
const BatchCompSubscriptions = lazy(() =>
  import("./components/Subscription/Subscriptions/BatchCompSubscriptions")
);

const PgmAds = lazy(() => import("./components/PgmAds/index"));
const GamHistory = lazy(() => import("./components/GAMHistory/index"));
const BillingOptions = lazy(() => import("./components/GAMBillingOptions/index"));
const TaskList = lazy(() => import("./components/TaskList/index"));

const PgmOrders = lazy(() => import("./components/PgmAds/PgmOrders/index"));

const TextEditor = lazy(() =>
  import("./components/Production/Orders/TextEditor")
);

const RateCardAllocations = lazy(() =>
  import("./components/Admin/RateCards/RateCardAllocations/index")
);
const JobTextView = lazy(() =>
  import("./components/AR/JobSearch/components/JobTextView")
);

const Products = lazy(() => import("./components/Admin/Products/index"));

const MasterPages = lazy(() => import("./components/Admin/MasterPages/index"));

const OpenAIUsageReport = lazy(() => import("./components/Admin/OpenAI/Reports/UsageReport"));

const SubscriptionProcessReport = lazy(() =>
  import("./components/Subscription/ProcessReport")
);

const ProductCategories = lazy(() =>
  import("./components/Production/ProductionTypes/ProductCategories")
);

const ProductCreate = lazy(() =>
  import("./components/Admin/Products/createEdit")
);
const MMToQuickBooks = lazy(() => import("./components/QuickBooks/MMToQB"));

const ProductionProposalStages = lazy(() =>
  import("./components/Production/ProjectStages")
);
const AdMasterList = lazy(() => import("./components/Admin/AdMaster/index"));

const EditCOOrder = lazy(() =>
  import("./components/Production/ChangeOrder/EditOrder")
);

const PreflightImport=lazy(()=>import("./components/PreflightImport"))
const AutomationList = lazy(() => import ("./components/AR/Automation/AutomationList"))
const BatchBillingStatus = lazy(() => import("./components/AR/Automation/Schedule_BatchBillingStatus"))
const PrepayApplyStatus = lazy(() => import("./components/AR/Automation/Schedule_PrepayApplyStatus"))

const CustomActionLogs=lazy(()=>import("./components/CustomActionLog/CustomActionLog"))

const SubscriptionInternalDashboard = lazy(() => import("./components/Subscription/InternalDashboard"))
const EmergencyBackup = lazy(() =>
  import("./components/Admin/Backups/index")
);
const CreateBackupPlan = lazy(() =>
  import("./components/Admin/Backups/EmergencyBackup")
);

const EditBackupPlan = lazy(() =>
  import("./components/Admin/Backups/EditBackup")
);

const PreflightMediaKit=lazy(()=>import("./components/PreflightMediaKit/index"))


const FileExplorer=lazy(()=>import("./components/FileExplorer/index"))

const GLAccountCodes = lazy(() => import("./components/AR/GLCodes/GLAccountCodes"));

const routes = [
  {
    path: "/blank",
    component: Blank,
    description: "Blank",
    showNotification: false,
  },
  {
    path: "/Admin/AdMasterList",
    component: AdMasterList,
    description: "Ad Master List",
    showNotification: false,
  },
  {
    path: "/customer/proposals/view/SignTerms",
    component: SignTermsAndConditions,
    description: "Sign Terms and Conditions",
    showNotification: false,
  },
  {
    path: "/QuickBooks/LinkTaxes",
    component: MMToQuickBooks,
    description: "MMtoQb",
  },
  {
    path: "/TextEditor",
    component: TextEditor,
    description: "TextEditor",
    showNotification: false,
  },
  {
    path: "/programmaticAds",
    component: PgmAds,
    description: "PgmAds",
    showNotification: false,
  },
  {
    path: "/gamHistory",
    component: GamHistory,
    description: "GamHistory",
    showNotification: false,
  },
  {
    path: "/billingOptions",
    component: BillingOptions,
    description: "BillingOptions",
    showNotification: false,
  },
  {
    path: "/newTaskList",
    component: TaskList,
    description: "TaskList",
    showNotification: false,
  },
  {
    path: "/contactedit",
    component: ContactEdit,
    description: "Contactedit",
    showNotification: true,
    DropDownDescription: "Contact Edit",
  },
  {
    path: "/Customer/Proposals/View",
    component: Proposals,
    description: "Proposals",
    showNotification: true,
    DropDownDescription: "Proposals",
  },
  {
    path: "/Contacts/Create",
    component: AddContact,
    description: "AddContact",
    showNotification: true,
    DropDownDescription: "Add Contact",
  },
  {
    path: "/AR/BatchBilling",
    component: BatchBilling,
    description: "BatchBilling",
    showNotification: true,
    DropDownDescription: "Batch Billing",
  },
  {
    path: "/AR/PrepayApply",
    component: PrepayApply,
    description: "PrepayApply",
    showNotification: true,
    DropDownDescription: "Prepay Apply",
  },
  {
    path: "/AR/JobSearch",
    component: JobSearch,
    description: "JobSearch",
    showNotification: true,
    DropDownDescription: "Job Search",
  },
  {
    path: "/AR/JobView",
    component: JobView,
    description: "JobView",
    showNotification: true,
    DropDownDescription: "Job View",
  },
  {
    path: "/search",
    component: Search,
    description: "Search",
    showNotification: true,
    DropDownDescription: "Basic Search",
  },
  {
    path: "/templates",
    component: IndexBase,
    description: "Indexbase",
    showNotification: false,
  },
  {
    path: "/templateDetails",
    component: TemplateDetails,
    description: "Templatedetails",
    showNotification: true,
    DropDownDescription: "Template Details",
  },
  {
    path: "/admin/defaultInstallmentDates",
    component: DefaultInstallmentDates,
    description: "Defaultinstallmentdates",
    showNotification: false,
  },
  {
    path: "/admin/PublisherInfo",
    component: PublisherInfo,
    description: "Publisherinfo",
    showNotification: true,
    DropDownDescription: "Publisher Info",
  },
  {
    path: "/admin/CustomerPortal",
    component: CustomerPortalSettings,
    description: "Customerportalsettings",
    showNotification: true,
    DropDownDescription: "Customer Portal Settings",
  },
  {
    path: "/admin/CreateApiUser",
    component: CreateAPIUserToken,
    description: "Createapiusertoken",
    showNotification: false,
  },
  {
    path: "/admin/CreateSSOToken",
    component: CreateSSOUserToken,
  },
  {
    path: "/Admin/CustomFields",
    component: CustomFields,
  },
  {
    path: "/Admin/EmergencyBackup/list",
    component: EmergencyBackup,
    description: "EmergencyBackups",
    showNotification: false,
    DropDownDescription: "Display Backups"
  },
  {
    path: "/Admin/EmergencyBackup/create",
    component: CreateBackupPlan,
    description: "CreateBackupPlan",
    showNotification: false,
    DropDownDescription: "Create Backup Plan",
  },
  {
    path: "/Admin/EmergencyBackup/edit",
    component: EditBackupPlan,
    description: "EditBackupPlan",
    showNotification: false,
    DropDownDescription: "Edit Backup Plan",
  },
  {
    path: "/admin/HelpSite",
    component: HelpSite,
  },
  {
    path: "/Admin/ProjectFields",
    component: ProjectFields,
  },
  {
    path: "/editorial/management",
    component: EditorialManagement,
    description: "Editorialmanagement",
    showNotification: true,
    DropDownDescription: "Editorial Management",
  },
  {
    path: "/editorial/add",
    component: EditorialAdd,
    description: "Editorialadd",
    showNotification: false,
    DropDownDescription: "Editorial Add",
  },
  {
    path: "/distribution/edit",
    component: EditDistribution,
    description: "Editdistribution",
    showNotification: false,
    DropDownDescription: "Edit Distribution",
  },
  {
    path: "/distribution/returns",
    component: DistributionReturns,
    description: "Distributionreturns",
    showNotification: true,
    DropDownDescription: "Distribution Returns",
  },
  {
    path: "/template/preview",
    component: TemplatePreview,
    description: "Templatepreview",
    showNotification: false,
    DropDownDescription: "Template Preview",
  },
  {
    path: "/contactsearch",
    component: ContactSearch,
    description: "Contactsearch",
    showNotification: true,
    DropDownDescription: "Contact Search",
  },
  {
    path: "/module/criteria",
    component: ModuleCriteria,
    description: "Module Criteria",
    showNotification: true,
    DropDownDescription: "Module Criteria",
  },
  {
    path: "/template/preview",
    component: TemplatePreview,
    description: "Templatepreview",
    showNotification: false,
    DropDownDescription: "Template Preview",
  },
  {
    path: "/opportunities/add",
    component: OpportunityAdd,
    description: "Opportunityadd",
    showNotification: false,
    DropDownDescription: "Opportunity Add",
  },
  {
    path: "/opportunities",
    component: Opportunities,
    description: "Opportunities",
    showNotification: true,
    DropDownDescription: "Opportunities",
  },
  {
    path: "/jobjacket",
    component: JobJacket,
    description: "Jobjacket",
    showNotification: true,
    DropDownDescription: "Job Details",
  },
  {
    path: "/CustomerApprovalInternal",
    component: CustomerApprovalInternal,
    description: "CustomerApprovalInternal",
    showNotification: false,
    DropDownDescription: "Customer Approval Internal",
  },
  {
    path: "/websitesetup",
    component: WebsiteSetup,
    description: "Websitesetup",
    showNotification: false,
    DropDownDescription: "Website Setup",
  },

  {
    path: "/paymentplan/installment",
    component: BillSearch,
    description: "ProcessPaymentPlans",
    showNotification: true,
    DropDownDescription: "Process Payment Plans",
  },

  {
    path: "/QuickBooks/SendDataToQB",
    component: QuickBooks,
    description: "QuickbooksSendDataToQB",
    showNotification: true,
    DropDownDescription: "QuickBooks SendDataToQB",
  },
  {
    path: "/production/orderentry",
    component: OrderEntry,
    description: "Orderentry",
    showNotification: true,
    DropDownDescription: "Order Entry (New)",
  },
  {
    path: "/production/proposal/templatelineitem",
    component: ProposalLineItemTemplate,
    description: "LineItem Template",
    showNotification: true,
    DropDownDescription: "Proposal LineItem Template",
  },
  {
    path: "/programmaticAds/Orders/create",
    component: PgmOrders,
    description: "PgmOrders",
    showNotification: true,
    DropDownDescription: "PGM Orders",
  },
  {
    path: "/production/proposal",
    component: ProposalEntry,
    description: "Proposal",
    showNotification: true,
    DropDownDescription: "Proposal Entry",
  },
  {
    path: "/production/changeorder",
    component: ChangeOrderEntry,
    description: "ChangeOrder",
    showNotification: true,
    DropDownDescription: "Change Order Entry",
  },
  {
    path: "/Production/MutipleProducts",
    component: MultipleProducts,
    description: "MultipleProducts",
    showNotification: false,
    DropDownDescription: "Multiple Products Copy",
  },
  {
    path: "/production/orderentry/create",
    component: CreateOrder,
    description: "Createorder",
    showNotification: false,
    DropDownDescription: "Order Entry Create",
  },
  {
    path: "/production/orderentry/createwithnoprice",
    component: CreateOrderWithNoPrice,
    description: "CreateOrderWithNoPrice",
    showNotification: false,
    DropDownDescription: "Order Entry Create With No Price",
  },
  {
    path: "/production/orderentry/edit",
    component: EditOrder,
    description: "Editorder",
    showNotification: false,
    DropDownDescription: "Order Entry Edit",
  },
  {
    path: "/production/orderlist",
    component: OrderList,
    description: "Orderlist",
    showNotification: true,
    DropDownDescription: "Order List",
  },
  {
    path: "/Production/ProposalList",
    component: ProposalList,
    description: "Proposallist",
    showNotification: true,
    DropDownDescription: "Proposal List",
  },
  {
    path: "/Production/ChangeOrderList",
    component: ChangeOrderList,
    description: "ChangeOrderlist",
    showNotification: true,
    DropDownDescription: "Change Order List",
  },
  {
    path: "/Production/ProductTypes",
    component: ProductionTypes,
    description: "ProductionTypes",
    showNotification: true,
    DropDownDescription: "Product Types",
  },
  {
    path: "/QuickBooks/Setup",
    component: QBSetup,
    description: "QuickBooksSetup",
    showNotification: true,
    DropDownDescription: "QuickBooks Setup",
  },
  {
    path: "/QuickBooks/LinkAccount",
    component: LinkAccounts,
    description: "QuickBooksLinkAccounts",
    showNotification: true,
    DropDownDescription: "QuickBooks Link Accounts",
  },
  {
    path: "/QuickBooks/LinkCustomer",
    component: LinkCustomers,
    description: "QuickBooksLinkCustomers",
    showNotification: true,
    DropDownDescription: "QuickBooks Link Customers",
  },
  {
    path: "/subscription/coupons/list",
    component: Coupons,
    description: "Coupons",
    showNotification: true,
    DropDownDescription: "Coupons",
  },
  {
    path: "/subscription/coupons/create",
    component: CreateCoupon,
    description: "CreateCoupon",
    showNotification: false,
    DropDownDescription: "Coupon Create",
  },
  {
    path: "/subscription/coupons/edit",
    component: EditCoupon,
    description: "EditCoupon",
    showNotification: false,
    DropDownDescription: "Coupon Edit",
  },
  {
    path: "/subscription/plans/list",
    component: Plans,
    description: "Plans",
    showNotification: true,
    DropDownDescription: "Plans",
  },
  {
    path: "/subscription/plans/create",
    component: CreatePlan,
    description: "Createplan",
    showNotification: false,
    DropDownDescription: "Create Plan",
  },
  {
    path: "/subscription/plans/edit",
    component: EditPlan,
    description: "Editplan",
    showNotification: false,
    DropDownDescription: "Edit Plan",
  },
  {
    path: "/subscriptions/list",
    component: Subscriptions,
    description: "Subscriptions",
    showNotification: true,
    DropDownDescription: "Subscriptions Search",
  },
  {
    path: "/subscriptions/create",
    component: CreateSubscription,
    description: "Createsubscription",
    showNotification: true,
    DropDownDescription: "Subscriptions Create",
  },
  {
    path: "/subscriptions/edit",
    component: EditSubscription,
    description: "Editsubscription",
    showNotification: true,
    DropDownDescription: "Subscriptions Edit",
  },
  {
    path: "/subscriptions/renew",
    component: RenewSubscription,
    description: "Renewsubscription",
    showNotification: true,
    DropDownDescription: "Subscriptions Renew",
  },
  {
    path: "/subscriptions/inbetween",
    component: InBetweenSubscription,
    description: "InBetweenSubscription",
    DropDownDescription: "InBetween Subscription"
  },
  {
    path: "/subscriptions/copy",
    component: CopySubscription,
    description: "CopySubscription",
    showNotification: true,
    DropDownDescription: "Subscriptions Copy",
  },
  {
    path: "/subscriptions/view",
    component: SubscriptionView,
    description: "SubscriptionView",
    showNotification: false,
    // DropDownDescription:"Subscriptions Edit"
  },
  {
    path: "/subscription/addons/list",
    component: Addon,
    description: "Addon",
    showNotification: true,
    DropDownDescription: "Addons",
  },
  {
    path: "/subscription/addons/create",
    component: CreateAddon,
    description: "Createaddon",
    showNotification: false,
    DropDownDescription: "Addon Create",
  },
  {
    path: "/subscription/addons/edit",
    component: EditAddon,
    description: "Editaddon",
    showNotification: false,
    DropDownDescription: "Addon Edit",
  },
  {
    path: "/contactSearch/gmailtemplates",
    component: GmailTemplate,
    description: "Gmailtemplate",
    showNotification: false,
    DropDownDescription: "Gmail Template",
  },
  {
    path: "/contactSearch/gmailtemplates/edit",
    component: GmailTemplateEdit,
    description: "Gmailtemplateedit",
    showNotification: false,
    DropDownDescription: "Gmail Template Edit",
  },
  {
    path: "/subscription/basicsearch",
    component: SubscriptionBasicSearch,
    description: "Subscriptionbasicsearch",
    showNotification: true,
    DropDownDescription: "Subscriptions Basic Search",
  },
  {
    path: "/subscription/FulfilmentPoints/List",
    component: FulfilmentPoints,
    description: "Fulfilmentpoints",
    showNotification: true,
    DropDownDescription: "Fulfilment Points",
  },
  {
    path: "/Admin/CancellationReasons",
    component: CancelReasons,
    description: "Cancelreasons",
    showNotification: true,
    DropDownDescription: "Cancel Reasons",
  },
  {
    path: "/gmaildetailsview",
    component: GmailDetailsView,
    description: "Gmaildetailsview",
    showNotification: false,
    DropDownDescription: "Gmail Details View",
  },
  {
    path: "/Admin/Taxes",
    component: Taxes,
    description: "Taxes",
    showNotification: true,
    DropDownDescription: "Taxes",
  },
  {
    path: "/Activities/Search",
    component: ActivitiesSearch,
    description: "ActivitiesSearch",
    showNotification: true,
    DropDownDescription: "Activities Search",
  },
  {
    path: "/AR/Invoices/Search",
    component: Invoices,
    description: "Invoices",
    showNotification: true,
    DropDownDescription: "Invoices Search",
  },
  {
    path: "/AR/Invoices/Create",
    component: InvoicesCreate,
    description: "Invoicescreate",
    showNotification: true,
    DropDownDescription: "Invoices Create",
  },
  {
    path: "/AR/Automation",
    component: Automation,
    description: "Automation",
    showNotification: true,
    DropDownDescription: "Automation",
  },
  {
    path: "/ScheduleReport",
    component: ScheduleReport,
    description: "Schedule Report",
    showNotification: true,
    DropDownDescription: "Schedule Report",
  },
  {
    path: "/AR/BatchBillingStatus",
    component: BatchBillingStatus,
    description: "Batch Billing Status",
    showNotification: true,
    DropDownDescription: "Batch Billing Status",
  },
  {
    path: "/AR/PrepayApplyStatus",
    component: PrepayApplyStatus,
    description: "Prepay Apply Status",
    showNotification: true,
    DropDownDescription: "Prepay Apply Status",
  },
  {
    path: "/production/ordersearch",
    component: OrderSearch,
    description: "Ordersearch",
    showNotification: true,
    DropDownDescription: "Production Module",
  },
  {
    path: "/production/changeordersearch",
    component: ChangeOrderSearch,
    description: "ChangeOrderSearch",
    showNotification: true,
    DropDownDescription: "Change Order Module",
  },
  {
    path: "/dashboards",
    component: Dashboards,
    description: "Dashboards",
    showNotification: true,
    DropDownDescription: "Dashboards",
  },
  {
    path: "/dummy1",
    component: Dummy1,
    description: "Dummy1",
    showNotification: false,
    DropDownDescription: "Dummy1",
  },
  {
    path: "/Template/LineItems",
    component: LineItems,
    description: "LineItems",
    showNotification: false,
    DropDownDescription: "Template LineItems",
  },
  {
    path: "/Template/LineItems/Create",
    component: CreateLineItem,
    description: "LineItemsCreate",
    showNotification: false,
    DropDownDescription: "Template LineItems Create",
  },
  {
    path: "/Template/LineItems/Edit",
    component: EditLineItem,
  },

  {
    path: "/Template/LineItems/Themes",
    component: LinesItemThemes,
    description: "LineItemsThemes",
    showNotification: false,
    DropDownDescription: "Template LineItems Themes",
  },

  {
    path: "/Template/LineItems/Themes/Create",
    component: CreateLineItemTheme,
    description: "CreateLineItemsTheme",
    showNotification: false,
    DropDownDescription: "Create Template LineItems Themes",
  },

  {
    path: "/production/ordersearch/basicsearch",
    component: BasicOrderSearch,
    description: "BasicOrderSearch",
    showNotification: false,
    DropDownDescription: "Order Basic Search",
  },
  {
    path: "/AR/Reports/Transaction",
    component: TransactionReport,
    description: "TransactionReport",
    showNotification: true,
    DropDownDescription: "Transaction Report",
  },
  {
    path: "/AR/Reports/AgingSummary",
    component: AgingReport,
    description: "AgingSummary",
    showNotification: true,
    DropDownDescription: "Aging Summary Report",
  },
  {
    path: "/AR/Reports/FileStorageBilling",
    component: AWSBillingReport,
    description: "AWSBillingReport",
    showNotification: true,
    DropDownDescription: "AWS Billing Report",
  },
  {
    path: "/mmnotification",
    component: Notifications,
    description: "Notifications",
    showNotification: false,
    DropDownDescription: "Notifications",
  },
  {
    path: "/subscription/AddonGroups",
    component: AddonGroup,
    description: "AddonGroup",
    showNotification: false,
    DropDownDescription: "Addon Groups",
  },
  {
    path: "/proposalrenew",
    component: RenewProposal,
    description: "RenewProposal",
    showNotification: false,
    DropDownDescription: "RenewProposal",
  },
  {
    path: "/Admin/Stages",
    component: ProductionOrderStages,
    description: "Production Order Stages",
    showNotification: true,
    DropDownDescription: "Production Order Stages",
  },
  {
    path: "/contactmanagement/prospectingstages",
    component: ProspectingStages,
    description: "Prospecting Stages",
    showNotification: true,
    DropDownDescription: "Prospecting Stages",
  },
  {
    path: "/contactmanagement/leadtypes",
    component: LeadTypes,
    description: "Lead Types",
    showNotification: true,
    DropDownDescription: "Lead Types",
  },
  {
    path: "/Admin/MultiCurrency",
    component: MultiCurrency,
    description: "Multi Currency",
  },
  {
    path: "/AR/Schedule/List",
    component: AutomationList,
    description: "Automation List",
  },
  {
    path: "/AR/Statements",
    component: Statements,
    description: "Statements",
    showNotification: true,
    DropDownDescription: "Statements",
  },
  {
    path: "/AR/PayWithSavedCards",
    component: PayWithSavedCards,
    description: "Pay With SavedCards",
  },
  {
    path: "/Admin/ContactGroup",
    component: ContactGroups,
    description: "Contact Groups",
  },
  {
    path: "/Admin/ContactType",
    component: ContactTypes,
    description: "Contact Types",
  },
  {
    path: "/Admin/MultiCurrency/RateCard",
    component: Ratecards,
    description: "Rate cards",
  },
  {
    path: "/Template/LineItems",
    component: LineItems,
    description: "LineItems",
    showNotification: false,
    DropDownDescription: "Template LineItems",
  },
  {
    path: "/Template/LineItems/Create",
    component: CreateLineItem,
    description: "LineItemsCreate",
    showNotification: false,
    DropDownDescription: "Template LineItems Create",
  },
  {
    path: "/Template/LineItems/Edit",
    component: EditLineItem,
  },
  {
    path: "/production/ordersearch/basicsearch",
    component: BasicOrderSearch,
    description: "BasicOrderSearch",
    showNotification: false,
    DropDownDescription: "Order Basic Search",
  },
  {
    path: "/mmnotification",
    component: Notifications,
    description: "Notifications",
    showNotification: false,
    DropDownDescription: "Notifications",
  },
  {
    path: "/subscription/AddonGroups",
    component: AddonGroup,
    description: "AddonGroup",
    showNotification: false,
    DropDownDescription: "Addon Groups",
  },
  {
    path: "/proposalrenew",
    component: RenewProposal,
    description: "RenewProposal",
    showNotification: false,
    DropDownDescription: "RenewProposal",
  },
  {
    path: "/ProfileService",
    component: ManageProfileService,
    description: "Manage user profile",
    showNotification: true,
    DropDownDescription: "Manage user profile",
  },
  {
    path: "/Reports/Sales",
    component: SalesModule,
    description: "Sales Module",
    showNotification: true,
    DropDownDescription: "Sales Module",
  },
  {
    path: "/Reports/SalesReportsModule",
    component: SalesByModule,
    description: "Revenue and Goals Report",
    showNotification: true,
    DropDownDescription: "Revenue and Goals Report",
  },
  {
    path: "/CampaignSearch",
    component: CampaignSearch,
    description: "Campaign Management Module",
    showNotification: true,
    DropDownDescription: "Campaign Management Module",
  },
  {
    path: "/Reports/Inventory",
    component: Inventory,
    description: "Inventory Report",
    showNotification: true,
    DropDownDescription: "Inventory Report",
  },
  {
    path: "/module/summary",
    component: SummaryDashboard,
    description: "Custom Summary Dashboard",
    showNotification: true,
    DropDownDescription: "Custom Summary Dashboard",
  },
  {
    path: "/Users/APIUserAccount",
    component: APIUserAccount,
    description: "User Account APIs",
    showNotification: true,
    DropDownDescription: "User Account APIs",
  },
  {
    path: "/Users/AddUsers",
    component: AddOrEditUsers,
    description: "Add or Edit User Account",
    showNotification: true,
    DropDownDescription: "Add or Edit User Account",
  },
  {
    path: "/Users/List",
    component: UserList,
    description: "UserList",
    showNotification: true,
    DropDownDescription: "Users List",
  },
  {
    path: "/Users/Account",
    component: UserAccount,
    description: "UserAccount",
    showNotification: true,
    DropDownDescription: "Users Account",
  },
  {
    path: "/Users/Roles/List",
    component: UserRolesList,
    description: "UserRolesList",
    showNotification: true,
    DropDownDescription: "Users Roles List",
  },
  {
    path: "/Users/Role",
    component: UserRoleAccount,
    description: "UserRoleAccount",
    showNotification: true,
    DropDownDescription: "Users Role Account",
  },
  {
    path: "/imagepreview",
    component: ImagePreview,
    description: "Image preview",
    showNotification: true,
    DropDownDescription: "Image preview",
  },
  {
    path: "/DigitalAds/LinkDigitalAdvertiser",
    component: DigitalAdsMapping,
    description: "DigitalAdsMapping with magazine manager",
    showNotification: true,
    DropDownDescription: "Mapping the Adcellerant Details",
  },
  {
    path: "/GAM/Creatives",
    component: GAMCreatives,
    description: "GAM Creatives",
    showNotification: true,
    DropDownDescription: "Testing",
  },
  {
    path: "/ce/Login",
    component: CELogin,
  },
  {
    path: "/ce/addemailnote",
    component: AddEmailNote,
  },
  {
    path: "/ce/displaynotes",
    component: DisplayNotes,
  },
  {
    path: "/ce/AuthIntegration",
    component: Authentication,
  },
  {
    path: "/ce/auth/redirectauthdetails",
    component: GmailRedirect,
  },
  {
    path: "/Calendar",
    component: CalendarEvents,
    description: "Calendar",
    showNotification: true,
    DropDownDescription: "Calendar",
  },
  {
    path: "/Footermanagement/EmailFooter",
    component: EmailFooter,
    description: "Email Footer",
    showNotification: true,
    DropDownDescription: "Email Footer",
  },
  {
    path: "/Opportunities/OpportunityStages",
    component: OpportunityStages,
    description: "Opportunity Stages",
    showNotification: true,
    DropDownDescription: "Opportunity Stages",
  },
  {
    path: "/subscription/Source/List",
    component: Source,
    description: "Source",
    showNotification: true,
    DropDownDescription: "Source",
  },
  {
    path: "/Admin/RequiredFields",
    component: RequiredFields,
    description: "Required Fields",
    showNotification: true,
    DropDownDescription: "Required Fields",
  },
  {
    path: "/EfficiencyAnalyser",
    component: EfficiencyAnalyser,
    description: "EfficiencyAnalyser",
    showNotification: true,
    DropDownDescription: "EfficiencyAnalyser",
  },
  {
    path: "/AR/JobTextView",
    component: JobTextView,
    description: "JobTextView",
    showNotification: false,
    DropDownDescription: "JobTextView",
  },
  {
    path: "/Reports/ShareReport",
    component: ShareReport,
    description: "ShareReport",
    showNotification: true,
    DropDownDescription: "ShareReport",
  },
  {
    path: "/Reports/ActivityModule",
    component: ActivityModule,
    description: "ActivityModule",
    showNotification: true,
    DropDownDescription: "ActivityModule",
  },
  {
    path: "/subscriptions/batchcomp",
    component: BatchCompSubscriptions,
    description: "Batch Create Comp Subscriptions",
    showNotification: true,
    DropDownDescription: "Batch Create Comp Subscriptions",
  },
  {
    path: "/User/AnalyticsDashboard",
    component: AnalyticsDashboard,
    description: "Analytics Dashboard",
    showNotification: true,
    DropDownDescription: "Analytics Dashboard",
  },
  {
    path: "/Admin/RateCardAllocations",
    component: RateCardAllocations,
    description: "RateCard",
    showNotification: true,
    DropDownDescription: "RateCard",
  },
  {
    path: "/OpenAI",
    component: OpenAI,
    description: "Calling Open Ai Component",
    showNotification: true,
    DropDownDescription: "Calling Open Ai Component",
  },
  {
    path: "/AssistantAPI",
    component: AssistantAPI,
    description: "Assistant Playground",
    showNotification: true,
    DropDownDescription: "Assistant Playground",
  },
  {
    path: "/AILetterWritter",
    component: AILetterWriter,
    description: "AI Letter Writter",
    showNotification: true,
    DropDownDescription: "AI Letter Writter",
  },
  {
    path: "/AITextImprover",
    component: AITextImprover,
    description: "AI Text Improver",
    showNotification: true,
    DropDownDescription: "AI Text Improver",
  },
  {
    path: "/Admin/Product/List",
    component: Products,
    description: "Products List",
    showNotification: true,
    DropDownDescription: "Products List",
  },
  {
    path: "/Admin/Master",
    component: MasterPages,
    description: "Master pages based on type",
    showNotification: false,
    DropDownDescription: "Master pages based on type",
  },
  {
    path: "/Admin/ProductCategory",
    component: ProductCategories,
    description: "Display Product Category",
    showNotification: true,
    DropDownDescription: "Display Product Category",
  },
  {
    path: "/Subscription/processReport",
    component: SubscriptionProcessReport,
    description: "Subscription Process Dashboard",
    showNotification: true,
    DropDownDescription: "Subscription Process Dashboard",
  },
  {
    path: "/Admin/Product",
    component: ProductCreate,
    description: "Products Create",
    showNotification: true,
    DropDownDescription: "Products Create",
  },
  
  {
    path: "/AR/Payments/CreditDebit",
    component: PaymentCreditDebit,
    description: "Payment Credit/Debit",
    showNotification: true,
    DropDownDescription: "Payment Credit/Debit",
  },
  {
    path: "/Admin/OpenAIUsageReport",
    component: OpenAIUsageReport,
    description: "OpenAI usage report",
    showNotification: false,
    DropDownDescription: "OpenAI usage report",
  },
  {
    path: "/Admin/Stages",
    component: ProductionProposalStages,
    description: "Internal Approval Stages",
    showNotification: true,
    DropDownDescription: "Internal Approval Stages"
  },
  {
    path: "/production/changeorder/insertions/edit",
    component: EditCOOrder,
    description: "Edit_ChangeOrder's_order",
    showNotification: false,
    DropDownDescription: "Change Order's Order Entry Edit",
  },
  {
    path: "/PreflightImport",
    component: PreflightImport,
    description: "Preflight Import",
    // showNotification: true,
    // DropDownDescription: "Preflight Import"
  },
  {
    path: "/CustomActionLog/CustomActionLog",
    component: CustomActionLogs,
    description: "Custom Logs",
    showNotification: true,
    DropDownDescription: "Products Create"
  },
  {
    path: "/Subscription/InternalDashboard",
    component: SubscriptionInternalDashboard,
    description: "Subscription Dashboard",
    DropDownDescription: "Subscription Internal Dashboard",
    showNotification: false
  },
  {
    path: "/developer/icons",
    component: Icons,
    description: "MM Icons Page",
    showNotification: false,
    DropDownDescription: "MM Icons Page",
  },
  {
    path: "/FileExplorer",
    component: FileExplorer,
    description: "File Explorer",
    showNotification: true,
    DropDownDescription: "File Explorer"
  },
  {
    path: "/tokengenerator",
    component: TokenGenerator,
    description: "Token Generator",
    showNotification: false,
  },
  {
    path: "/PreflightMediaKit",
    component: PreflightMediaKit,
    description: "Preflight MediaKit",
  },
  {
    path: "/AR/GLAccountCodes",
    component: GLAccountCodes,
    description: "ARGLAccountCodes",
    showNotification: true,
    DropDownDescription: "AR GL Account Codes",
  },
];

export default routes;